













import { Component, Vue, Prop } from 'vue-property-decorator';

import { ImageSourceSet } from 'client-website-ts-library/types';

@Component
export default class SideBySide extends Vue {
  @Prop()
  private readonly image!: string | ImageSourceSet;

  @Prop()
  private readonly reversed!: boolean;

  @Prop()
  private readonly background!: string;

  @Prop()
  private readonly backgroundContainer!: string;

  @Prop()
  private readonly border!: string;
}
