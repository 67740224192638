// @ts-nocheck
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/properties/for-sale',
    name: 'Properties For Sale',
    component: () => import('../views/PropertiesForSale.vue'),
  },
  {
    path: '/properties/sold',
    name: 'Sold Properties',
    component: () => import('../views/PropertiesSold.vue'),
  },
  {
    path: '/forms/sales-appraisal',
    name: 'Sales Appraisal',
    component: () => import('../views/SalesAppraisal.vue'),
  },
  {
    path: '/blog',
    name: 'Blog',
    component: () => import('../views/Blog.vue'),
  },
  {
    path: '/contact-us',
    name: 'Contact',
    component: () => import('../views/ContactUs.vue'),
  },
  {
    path: '/blogs/:id',
    name: 'Blog',
    meta: {
      hide: true,
    },
    component: () => import('../views/BlogDetails.vue'),
  },
  {
    path: '/listings/:id',
    name: 'Listing',
    meta: {
      hide: true,
    },
    component: () => import('../views/ListingDetails.vue'),
  },
  {
    path: '/listings/:id/make-an-offer',
    name: 'Make an Offer',
    meta: {
      hide: true,
    },
    component: () => import('../views/ListingMakeAnOffer.vue'),
  },
  {
    path: '/profiles/:id',
    name: 'Profile',
    meta: {
      hide: true,
    },
    component: () => import('../views/AgentProfile.vue'),
  },
  {
    path: '/profiles/:id/properties',
    name: 'Profile Listings',
    meta: {
      hide: true,
    },
    component: () => import('../views/AgentProfileListings.vue'),
  },
  {
    path: '/profiles/:id/properties/sold',
    name: 'Profile Sold Listings',
    meta: {
      hide: true,
    },
    component: () => import('../views/AgentProfileListingsSold.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: () => import('../views/PrivacyPolicy.vue'),
  },
  {
    path: '/sitemap',
    name: 'Sitemap',
    component: () => import('../views/Sitemap.vue'),
  },
  {
    path: '/unsubscribe',
    name: 'Unsubscribe',
    meta: {
      hide: true,
    },
    component: () => import('../views/Unsubscribe.vue'),
  },
  {
    path: '/*',
    name: 'Not Found',
    meta: {
      hide: true,
    },
    component: () => import('../views/NotFound.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  try {
    if (process.env.NODE_ENV === 'production') {
      const fbToken = 'EABSCL6gXueIBO87aZBkV1tXZAWITIs5jGxcG6j6QXT8e5TLpk7s7kGkYhsUoKARPJq2yKlbgtB2jQ3gqs5QNvWUZCOc3HyKyPZBt4RXDrxRkAZBrsYVTy8pD9iWiQriZBwNj7hUXZALfKCGqn0aakY2aYbxZCsGZAdhmRJ2br7hvCG9Mt36TLWVmTQOxG9xWbTIRibwZDZD';
      const fbPixelId = 465441410512057;
      const fbApiVersion = 'v20.0';
      const fbUrl = `https://graph.facebook.com/${fbApiVersion}/${fbPixelId}/events?access_token=${fbToken}`;

      const eventTime = Math.round(Date.now() / 1000);
      const userAgent = window.navigator.userAgent;
      const userIpResponse = await fetch('https://api.ipify.org?format=json');
      const userIp = await userIpResponse.json();
      const sourceUrl = window.location.href;

      /* eslint-disable */
      fetch(fbUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          data: [
            {
              event_name: 'Page Visit',
              event_time: eventTime,
              action_source: 'website',
              event_source_url: sourceUrl,
              user_data: {
                client_ip_address: userIp.ip,
                client_user_agent: userAgent,
              },
            },
          ],
        }),
      });
      /* eslint-disable */
    }
  } catch (error) {
    console.error('Error sending Facebook Pixel event', error);
  }

  next();
});

export default router;
